import * as React from "react"
import VimeoPlayer from "react-player/vimeo"
import useDeviceDetect from "components/Utilities/useDeviceDetect"

function LiteVimeoEmbedComponent(props, ref) {
  const { isMobile } = useDeviceDetect()
  const [preconnected, setPreconnected] = React.useState(false)
  const [iframe, setIframe] = React.useState(false)
  const muted = isMobile
  const videoTitle = props.title
  const announceWatch = props.announce || "Watch"
  const posterUrl = props.poster
  const aspectHeight = props.aspectHeight || 9
  const aspectWidth = props.aspectWidth || 16
  const activatedClassImp = props.activatedClass || "ltv-activated"
  const playerClassImp = props.playerClass || "ltv-playbtn"
  const wrapperClassImp = props.wrapperClass || "vimeo-lite"
  const onIframeAdded = props.onIframeAdded || function () {}
  const rel = props.rel ? "prefetch" : "preload"
  const ContainerElement = props.containerElement || "article"

  const warmConnections = () => {
    if (preconnected) return
    setPreconnected(true)
  }

  const addIframe = () => {
    if (iframe) return
    setIframe(true)
  }

  React.useEffect(() => {
    if (iframe) {
      onIframeAdded()
    }
  }, [iframe])

  return (
    <>
      <link rel={rel} href={posterUrl} as="image" />
      <>
        {preconnected && (
          <>
            <link rel="preconnect" href="https://vimeo.com" />
          </>
        )}
      </>
      <ContainerElement
        onPointerOver={warmConnections}
        className={`${wrapperClassImp} ${iframe ? activatedClassImp : ""}`}
        onClick={addIframe}
        data-title={videoTitle}
        style={{
          backgroundImage: `url(${posterUrl})`,
          ...{
            "--aspect-ratio": `${(aspectHeight / aspectWidth) * 100}%`,
          },
        }}>
        <button
          type="button"
          className={playerClassImp}
          aria-label={`${announceWatch} ${videoTitle}`}
        />
        {iframe && (
          <VimeoPlayer
            className="absolute inset-0 z-10"
            url={props.url}
            playsinline={true}
            muted={muted}
            onReady={props.onReady}
            onStart={props.onStart}
            onPlay={props.onPlay}
            onPause={props.onPause}
            onEnded={props.onEnded}
            onDuration={props.onDuration}
            onProgress={props.onProgress}
            controls={true}
            playing={true}
            width="100%"
            height="100%"
            onError={props.onError}
          />
        )}
      </ContainerElement>
    </>
  )
}

export default React.forwardRef(LiteVimeoEmbedComponent)
