import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import CopyToClipboard from "react-copy-to-clipboard"
import { event } from "lib/gtag"
import Button from "components/UI/Button"
import { ReactComponent as CheckIcon } from "assets/icons/actionIcons/Check.svg"
import { ReactComponent as CopyIcon } from "assets/icons/actionIcons/Copy.svg"

const ShareLinkCopyButton = (props) => {
  const [copied, setCopied] = useState(false)

  const handleClipBoard = (url) => {
    event({ action: "Copy_Clipboard", category: "Click", label: url })
  }

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false)
      }, 2000)

      return () => clearTimeout(timer)
    }
  }, [copied, setCopied])

  return (
    <CopyToClipboard text={props.link} onCopy={() => setCopied(true)}>
      <Button
        className={props.className}
        onClick={() => handleClipBoard(props.link)}
        label={!copied ? "Copy Link" : "Copied"}
        variant="outlined"
        emphasis={!copied ? "extra" : "disabled"}
        theme="light"
        size={props.size}
        iconBefore={!copied ? <CopyIcon /> : <CheckIcon />}
      />
    </CopyToClipboard>
  )
}

ShareLinkCopyButton.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string.isRequired,
  size: PropTypes.string,
}

export default ShareLinkCopyButton
