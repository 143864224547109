import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import ErrorMessage from "components/UI/ErrorMessage"
import { event } from "lib/gtag"
import LiteYouTubeEmbed from "./LiteYouTubeEmbed"
import LiteVimeoEmbed from "./LiteVimeoEmbed"

const VIMEO_URL = /vimeo\.com\/.+/

const VideoPlayer = (props) => {
  const [canPlay, setCanPlay] = useState(false)
  const [isVimeo, setIsVimeo] = useState(false)
  const [onError, setOnError] = useState(null)
  const [duration, setDuration] = useState(0)
  const [playHalf, setPlayHalf] = useState(false)
  const [second, setSecond] = useState(0)
  const [isActive, setIsActive] = useState(false)
  const [counter, setCounter] = useState(0)
  const [send5, setSend5] = useState(false)
  const [send15, setSend15] = useState(false)
  const [send30, setSend30] = useState(false)

  useEffect(() => {
    setIsVimeo(VIMEO_URL.test(props.url))
    setCanPlay(true)
    let intervalId
    if (isActive) {
      intervalId = setInterval(() => {
        setSecond(counter)
        setCounter((counter) => counter + 1)
      }, 1000)
    }
    return () => clearInterval(intervalId)
  }, [isActive, counter, canPlay, isVimeo])

  const onReady = () => {
    event({
      action: "Ready",
      category: "Play",
      label: props.url,
      interaction: false,
    })
  }
  const onStart = () => {
    event({ action: "Start", category: "Play", label: props.url })
  }
  const onPlay = () => {
    setIsActive(true)
  }
  const onPause = () => {
    setIsActive(false)
  }
  const onEnded = () => {
    event({ action: "End", category: "Play", label: props.url })
  }
  const onDuration = (duration) => {
    setDuration(duration)
  }
  const onProgress = () => {
    if (!send30 && second >= 30) {
      setSend30(true)
      event({ action: "30_Sec", category: "Play", label: props.url })
    }
    if (!send15 && second >= 15) {
      setSend15(true)
      event({ action: "15_Sec", category: "Play", label: props.url })
    }
    if (!send5 && second >= 5) {
      setSend5(true)
      event({ action: "5_Sec", category: "Play", label: props.url })
    }
    if (!playHalf && second > duration / 2) {
      event({ action: "Half", category: "Play", label: props.url })
      setPlayHalf(true)
    }
  }
  const createErrorMessage = (e) => {
    if (e !== null && isFinite(e.toString())) {
      return `Unable to play video. Error : ${e}`
    } else if (e?.message !== null && e.message !== undefined) {
      return e.message
    }
    return e.toString()
  }

  return (
    <div className={classNames(props.className, "bg-base-indented-light")}>
      <div className="mx-auto box-x-wrapper lg:max-w-lg">
        <div className="relative w-full aspect-ratio-16/9">
          <div className="absolute inset-0">
            <input type="hidden" name="murl" value={props.url} />
            <div className="h-full player-wrapper">
              {canPlay ? (
                onError ? (
                  <ErrorMessage
                    className="w-full h-full"
                    title="It's gone flat"
                    description={createErrorMessage(onError)}
                    reloadButton
                    homeButton
                  />
                ) : isVimeo ? (
                  <LiteVimeoEmbed
                    className="absolute inset-0 z-10"
                    url={props.url}
                    title={props.title}
                    poster={props.poster}
                    onReady={onReady}
                    onStart={onStart}
                    onPlay={onPlay}
                    onPause={onPause}
                    onEnded={onEnded}
                    onDuration={onDuration}
                    onProgress={onProgress}
                    onError={onError}
                  />
                ) : (
                  <LiteYouTubeEmbed
                    className="absolute inset-0 z-10"
                    url={props.url}
                    title={props.title}
                    poster={props.poster}
                    onReady={onReady}
                    onStart={onStart}
                    onPlay={onPlay}
                    onPause={onPause}
                    onEnded={onEnded}
                    onDuration={onDuration}
                    onProgress={onProgress}
                    onError={onError}
                  />
                )
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

VideoPlayer.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default VideoPlayer
