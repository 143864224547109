import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { event } from "lib/gtag"
import Button from "components/UI/Button"
import { FacebookIcon } from "assets/icons"

const ShareLinkFacebookButton = (props) => {
  const handleShareFacebook = (url) => {
    event({ action: "Share_Facebook", category: "Click", label: url })
  }

  return (
    <Button
      className={classNames("bg-social-facebook", props.className)}
      onClick={() => handleShareFacebook(props.link)}
      href={"https://www.facebook.com/sharer/sharer.php?u=" + props.link}
      label="Share to Facebook"
      size={props.size}
      theme="dark"
      target="_blank"
      rel="noopener"
      iconBefore={<FacebookIcon />}
    />
  )
}

ShareLinkFacebookButton.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  size: PropTypes.string,
}

export default ShareLinkFacebookButton
