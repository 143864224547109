import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Link from "next/link"
import { event } from "lib/gtag"

import {
  SeriesIcon,
  PresentedByIcon,
  ProducedByIcon,
  NarratedByIcon,
  DirectedByIcon,
  FilmEditIcon,
  FilmIcon,
  EditIcon,
  SurferIcon,
  MusicIcon,
  CalendarIcon,
  RuntimeIcon,
  AdditionalFootageIcon,
  SupportedIcon,
  WaterFootageIcon,
  DroneFootageIcon,
  SurfboardIcon,
  InformationIcon,
} from "assets/icons"

const handleTagClick = (m_tag, tag_name) => {
  event({
    action: m_tag,
    category: "Click",
    label: tag_name,
  })
}

const VideoDescriptionBlock = (props) => {
  return (
    <div className={classNames("my-4 inline-block w-full", props.className)}>
      <div className="flex items-center tags-center">
        <VideoDescriptionBlockIcon blockTitle={props.title} />
        <h2 className="ml-1 fs-base-interface-bold">{props.title}</h2>
      </div>

      <ul className="mt-3">
        {props.tags.map((tag, i) => (
          <li
            className={`fs-base-interface-bold text-element-medium-emphasis-light ${
              i > 0 ? "mt-2" : ""
            }`}
            key={i}>
            {tag.tag_url ? (
              <Link
                href={`/tag/${encodeURIComponent(tag.tag_name)}`}
                prefetch={false}>
                <a
                  className="fs-base-interface-link"
                  onClick={() => handleTagClick(props.title, tag.tag_name)}>
                  {tag.tag_name}
                </a>
              </Link>
            ) : (
              tag.tag_name
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

const VideoDescriptionBlockIcon = ({ blockTitle }) => {
  const iconClassName = "w-6 h-6"

  if (blockTitle.match(/series/i)) {
    return <SeriesIcon className={iconClassName} />
  }
  if (blockTitle.match(/surfer/i)) {
    return <SurferIcon className={iconClassName} />
  }
  if (blockTitle.match(/film/i) && blockTitle.match(/edit/i)) {
    return <FilmEditIcon className={iconClassName} />
  }
  if (blockTitle.match(/film/i)) {
    return <FilmIcon className={iconClassName} />
  }
  if (blockTitle.match(/edit/i)) {
    return <EditIcon className={iconClassName} />
  }
  if (blockTitle.match(/produced/i)) {
    return <ProducedByIcon className={iconClassName} />
  }
  if (blockTitle.match(/directed/i)) {
    return <DirectedByIcon className={iconClassName} />
  }
  if (blockTitle.match(/narrated/i)) {
    return <NarratedByIcon className={iconClassName} />
  }
  if (blockTitle.match(/presented/i)) {
    return <PresentedByIcon className={iconClassName} />
  }
  if (blockTitle.match(/music/i)) {
    return <MusicIcon className={iconClassName} />
  }
  if (blockTitle.match(/year/i)) {
    return <CalendarIcon className={iconClassName} />
  }
  if (blockTitle.match(/additional/i)) {
    return <AdditionalFootageIcon className={iconClassName} />
  }
  if (blockTitle.match(/drone/i)) {
    return <DroneFootageIcon className={iconClassName} />
  }
  if (blockTitle.match(/water/i)) {
    return <WaterFootageIcon className={iconClassName} />
  }
  if (blockTitle.match(/supported/i)) {
    return <SupportedIcon className={iconClassName} />
  }
  if (blockTitle.match(/surfboard/i)) {
    return <SurfboardIcon className={iconClassName} />
  }
  if (blockTitle.match(/time/i)) {
    return <RuntimeIcon className={iconClassName} />
  }
  if (blockTitle.match(/featuring/i)) {
    return <SurferIcon className={iconClassName} />
  }
  return <InformationIcon className={iconClassName} />
}

VideoDescriptionBlock.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,

  tags: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      tag_name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
}

VideoDescriptionBlockIcon.propTypes = {
  blockTitle: PropTypes.string,
}

export default VideoDescriptionBlock
