import { string, node } from "prop-types"
import useShareLink from "./useShareLink"
import Modal from "components/UI/Modal"
import ShareLinkCopyButton from "./ClipboardButton"
import ShareLinkFacebookButton from "./FacebookButton"
import ShareLinkTwitterButton from "./TwitterButton"
import { event } from "lib/gtag"

const ShareLink = ({ className, title, url, children }) => {
  const { onClick, showShareModal, setShowShareModal } = useShareLink(
    title,
    url,
  )

  const handleCloseModal = () => {
    event({ action: "Close_Share", category: "Click", label: url })
    setShowShareModal(false)
  }

  return (
    <>
      <button className={className} onClick={onClick} aria-label="Share">
        {children}
      </button>

      {showShareModal && (
        <Modal
          type="action"
          overlay={true}
          title="Share"
          onClose={() => handleCloseModal()}>
          <ShareLinkCopyButton size="dynamic" link={window.location.href} />
          <ShareLinkFacebookButton
            size="dynamic"
            className="mt-4"
            link={window.location.href}
          />
          <ShareLinkTwitterButton
            size="dynamic"
            className="mt-4"
            link={window.location.href}
          />
        </Modal>
      )}
    </>
  )
}

ShareLink.propTypes = {
  children: node.isRequired,
  title: string.isRequired,
  url: string.isRequired,
  className: string,
}

export default ShareLink
