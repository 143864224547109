import React from "react"
import PropTypes from "prop-types"
import Link from "next/link"
import { event } from "lib/gtag"
import CountryFlag from "components/UI/CountryFlag"

const handlePlaceClick = (tag_name) => {
  event({
    action: "Place",
    category: "Click",
    label: tag_name,
  })
}

const VideoHeaderPlaces = (props) => {
  const places = props.places || []
  const section = places[places.length - 1]
  var country
  if (section && section.type == country) {
    country = section.tag_name
  }
  return (
    <ul className={props.className}>
      {props.places &&
        props.places.map((place, i) => (
          <li className={`flex items-center ${i > 0 ? "mt-1" : ""}`} key={i}>
            <CountryFlag
              className="w-6 h-6"
              country={place[place.length - 1].tag_name}
              unicode={place[place.length - 1].unicode}
            />
            <div className="ml-2 fs-base-interface-bold text-element-medium-emphasis-light">
              {place
                .map((tag, i) => {
                  return (
                    <Link key={i} href={tag.tag_url} prefetch={false}>
                      <a
                        className="fs-base-interface-link"
                        onClick={() => handlePlaceClick(tag.tag_name)}
                        key={tag.tag_name}>
                        {tag.tag_name}
                      </a>
                    </Link>
                  )
                })
                .reduce((prev, curr) => [prev, ", ", curr])}
            </div>
          </li>
        ))}
    </ul>
  )
}

VideoHeaderPlaces.propTypes = {
  className: PropTypes.string,
  places: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        tag_name: PropTypes.string.isRequired,
        tag_url: PropTypes.string.isRequired,
        unicode: PropTypes.string,
      }),
    ),
  ).isRequired,
}

export default VideoHeaderPlaces
