import PropTypes from "prop-types"
import {
  LinkVideoIcon,
  LinkIcon,
  ShopIcon,
  ArticleIcon,
  InstagramIcon,
  OndemandMovieIcon,
  YouTubeIcon,
  VimeoIcon,
  FacebookIcon,
  TwitterIcon,
  PinterestIcon,
  SnapchatIcon,
  SpotifyIcon,
} from "assets/icons"

const RelatedLinkIconByType = ({ type }) => {
  switch (type) {
    case 0:
      return <LinkVideoIcon />
    case 2:
      return <InstagramIcon />
    case 3:
      return <ArticleIcon />
    case 4:
      return <ShopIcon />
    case 5:
      return <OndemandMovieIcon />
    case 6:
      return <YouTubeIcon />
    case 7:
      return <VimeoIcon />
    case 8:
      return <FacebookIcon />
    case 9:
      return <TwitterIcon />
    case 10:
      return <PinterestIcon />
    case 11:
      return <SnapchatIcon />
    case 12:
      return <SpotifyIcon />
    case 1:
    default:
      return <LinkIcon />
  }
}

RelatedLinkIconByType.propTypes = {
  type: PropTypes.number,
}

export default RelatedLinkIconByType
