import * as React from "react"
import YouTubePlayer from "react-player/youtube"
import useDeviceDetect from "components/Utilities/useDeviceDetect"
import Head from "next/head"

function LiteYouTubeEmbedComponent(props, ref) {
  const { isMobile } = useDeviceDetect()
  const [preconnected, setPreconnected] = React.useState(false)
  const [iframe, setIframe] = React.useState(false)
  const muted = isMobile
  typeof props.playlistCoverId === "string"
    ? encodeURIComponent(props.playlistCoverId)
    : null
  const videoTitle = props.title
  const announceWatch = props.announce || "Watch"
  const posterUrl = props.poster + "?f=auto"
  const activatedClassImp = props.activatedClass || "lyt-activated"
  const aspectHeight = props.aspectHeight || 9
  const aspectWidth = props.aspectWidth || 16
  const playerClassImp = props.playerClass || "lty-playbtn"
  const wrapperClassImp = props.wrapperClass || "yt-lite"
  const onIframeAdded = props.onIframeAdded || function () {}
  const rel = props.rel ? "prefetch" : "preload"
  const ContainerElement = props.containerElement || "article"

  const warmConnections = () => {
    if (preconnected) return
    setPreconnected(true)
  }

  const addIframe = () => {
    if (iframe) return
    setIframe(true)
  }

  React.useEffect(() => {
    setIframe(false)
  }, [props.url])

  React.useEffect(() => {
    if (iframe) {
      onIframeAdded()
    }
  }, [iframe])

  return (
    <>
      {preconnected && (
        <Head>
          <link rel="preconnect" href="https://cdn.nobodysurf.com" />
          <link rel="dns-prefetch" href="https://cdn.nobodysurf.com" />
          <link rel={rel} href={posterUrl} as="image" />
        </Head>
      )}
      <ContainerElement
        onPointerOver={warmConnections}
        onClick={addIframe}
        className={`${wrapperClassImp} ${iframe ? activatedClassImp : ""}`}
        data-title={videoTitle}
        style={{
          backgroundImage: `url(${posterUrl})`,
          ...{
            "--aspect-ratio": `${(aspectHeight / aspectWidth) * 100}%`,
          },
        }}>
        <button
          type="button"
          className={playerClassImp}
          aria-label={`${announceWatch} ${videoTitle}`}
        />
        {iframe && (
          <YouTubePlayer
            className="absolute inset-0 z-10"
            url={props.url}
            playsinline={true}
            muted={muted}
            onReady={props.onReady}
            onStart={props.onStart}
            onPlay={props.onPlay}
            onPause={props.onPause}
            onEnded={props.onEnded}
            onDuration={props.onDuration}
            onProgress={props.onProgress}
            controls={true}
            playing={true}
            width="100%"
            height="100%"
            onError={props.onError}
          />
        )}
      </ContainerElement>
    </>
  )
}

export default React.forwardRef(LiteYouTubeEmbedComponent)
