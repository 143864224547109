import { useState } from "react"
import { event } from "lib/gtag"
import { isMobile } from "react-device-detect"

const useShareLink = (title, text, url) => {
  const [showShareModal, setShowShareModal] = useState(false)

  const onClick = () => {
    event({ action: "Share_Button", category: "Click", label: url })

    if ((typeof window !== "undefined" ? navigator.share : "") && isMobile) {
      navigator
        .share({
          title: title,
          text: text || title,
          url: url,
        })
        .then(() =>
          event({ action: "Share_Native", category: "Click", label: url }),
        )
        .catch(() => {
          event({ action: "Share_Native_Error", category: "Click", label: url })
          console.log(`Error: Can't show navigator.share`)
        })
    } else {
      setShowShareModal(true)
    }
  }

  return { onClick, showShareModal, setShowShareModal }
}

export default useShareLink
