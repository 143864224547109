import React, { useContext } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { ShowUnsupportedFeatureNoticeContext } from "context"
import Button from "components/UI/Button"
import ShareLink from "components/UI/ShareLink"
import { PlusIcon, ShareIcon, SaveIcon } from "assets/icons"
import { useAmp } from "next/amp"
import { event } from "lib/gtag"

const VideoHeaderControls = (props) => {
  const isAmp = useAmp()
  const url = typeof window !== "undefined" ? window.location.href : ""

  if (!isAmp) {
    const { setShowUnsupportedFeatureNotice } = useContext(
      ShowUnsupportedFeatureNoticeContext,
    )

    const handleSave = () => {
      event({ action: "Save", category: "Click", label: url })
      setShowUnsupportedFeatureNotice(true)
    }

    const handlePlus = () => {
      event({ action: "Plus", category: "Click", label: url })
      setShowUnsupportedFeatureNotice(true)
    }

    return (
      <div
        className={classNames(
          "flex justify-between items-center",
          props.className,
        )}>
        <Button
          label="Save"
          iconBefore={<SaveIcon />}
          theme="light"
          variant="outlined"
          aria-label="Save"
          onClick={handleSave}
        />

        <div className="flex items-center">
          <PlusIcon
            className="mr-6 cursor-pointer w-7 h-7"
            onClick={handlePlus}
          />

          <ShareLink title={props.title} text={props.title} url={url}>
            <ShareIcon className="cursor-pointer w-7 h-7" />
          </ShareLink>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

VideoHeaderControls.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default VideoHeaderControls
