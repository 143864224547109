import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { event } from "lib/gtag"
import ListSection from "components/UI/ListSection"
import ListCell from "components/UI/ListCell"
import RelatedLinkIconByType from "components/UI/RelatedLinkIconByType"

const handleMovieLinkClick = (link_url) => {
  event({
    action: "Link",
    category: "Click",
    label: link_url,
  })
}

const VideoRelatedLinks = (props) => {
  if (props.links && props.links.length != 0) {
    const rowLen = props.links.length
    return (
      <ListSection
        title="Related Links"
        className={classNames(props.className, "")}>
        {props.links &&
          props.links.map((link, i) => {
            return (
              <ListCell
                key={i}
                icon={<RelatedLinkIconByType type={link.link_type} />}
                label={link.link_text}
                href={link.link_url}
                target="_blank"
                rel="noopener"
                bottomDivider={!(rowLen === i + 1)}
                onClick={() => handleMovieLinkClick(link.link_url)}
              />
            )
          })}
      </ListSection>
    )
  }

  return null
}

VideoRelatedLinks.propTypes = {
  className: PropTypes.string,
  links: PropTypes.array,
}

export default VideoRelatedLinks
