import PropTypes from "prop-types"
import Page from "components/Page"
import Wrapper from "components/Wrapper"
import ErrorMessage from "components/UI/ErrorMessage"

const Error = ({ statusCode }) => {
  return (
    <Page>
      <Wrapper>
        <ErrorMessage
          className="my-16"
          statusCode={statusCode}
          reloadButton
          homeButton
        />
      </Wrapper>
    </Page>
  )
}

Error.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404
  return { statusCode }
}

Error.propTypes = {
  statusCode: PropTypes.number,
}

export default Error
