import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import VideoDescriptionBlock from "components/Video/Description/Block"

const VideoDescription = (props) => (
  <div
    className={classNames(
      props.className,
      "py-2 col-count-2 gap-x-4 md:col-count-3 lg:col-count-5",
    )}>
    {props.tags &&
      Object.keys(props.tags).map((section, i) => (
        <VideoDescriptionBlock
          key={i}
          title={section}
          tags={props.tags[section]}
        />
      ))}
  </div>
)

VideoDescription.propTypes = {
  className: PropTypes.string,
  tags: PropTypes.object.isRequired,
}

export default VideoDescription
