export function isEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

export function toQueryString(query) {
  const tags = formatTagQuery(query)
  var queryString = tags
    .map((tag) => "tags[]=" + encodeURIComponent(tag))
    .join("&")
  if (query["q"] !== undefined) {
    queryString += "&q=" + query["q"]
  }
  return queryString
}

/**
 * tags[]=hawaii&tags[]=fish
 * tags[0]=hawaii&tags[1]=fish
 *
 * のようなパラメータを
 *
 * ['hawaii', 'fish']
 *
 * のような配列にする
 *
 * @param {*} query
 */
export function formatTagQuery(query) {
  var tags = []
  if (query["tags[]"] !== undefined) {
    if (Array.isArray(query["tags[]"])) {
      tags = query["tags[]"]
    } else {
      tags.push(query["tags[]"])
    }
  } else {
    for (const [key, value] of Object.entries(query)) {
      if (key.match(/tags/)) {
        tags.push(value)
      }
    }
  }
  return tags
}
