const Publisher = () => {
  const info = {
    ["@type"]: "Organization",
    name: "rblue Inc.",
    logo: {
      ["@type"]: "ImageObject",
      url: "https://cdn.nobodysurf.com/com/img/NobodySurf_Logo_512-512.png",
      height: 512,
      width: 512,
    },
  }
  return info
}

export default Publisher
