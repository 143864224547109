import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { CountryFlagMap } from "assets/icons"
import { useAmp } from "next/amp"

const CountryFlag = (props) => {
  const unicode =
    props.unicode === undefined || props.unicode === ""
      ? "default"
      : props.unicode

  if (useAmp()) {
    return (
      <amp-img
        src={`/img/flags/${unicode}.png`}
        className={classNames(props.className, "")}
        alt={props.country}
        width="1.5rem"
        height="1.5rem"
      />
    )
  }

  const CountryFlag = CountryFlagMap[unicode]

  return (
    <CountryFlag
      className={classNames(props.className, "")}
      width="20px"
      height="20px"
      alt={props.country}
    />
  )
}

CountryFlag.propTypes = {
  unicode: PropTypes.string,
  country: PropTypes.string,
  className: PropTypes.string,
}

CountryFlag.defaultProps = {
  className: "",
}

export default React.memo(CountryFlag)
