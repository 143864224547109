import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Divider from "components/UI/Divider"
import VideoHeaderPlaces from "components/Video/Header/Places"
import VideoHeaderControls from "components/Video/Header/Controls"

const VideoHeader = (props) => {
  return (
    <div className={classNames(props.className, "")}>
      <div className="py-4 md:py-6">
        <div>
          <h1 className="fs-2x-large-bold">{props.title}</h1>
          <VideoHeaderPlaces className="mt-3" places={props.places} />
        </div>

        <VideoHeaderControls className="mt-4" title={props.title} />
      </div>

      {props.bottomDivider && <Divider />}
    </div>
  )
}

VideoHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  places: PropTypes.array,
  bottomDivider: PropTypes.bool,
}

export default VideoHeader
