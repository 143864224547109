import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "components/UI/Button"
import { TwitterIcon } from "assets/icons"
import { event } from "lib/gtag"

const ShareLinkTwitterButton = (props) => {
  const handleShareTwitter = (url) => {
    event({ action: "Share_Twitter", category: "Click", label: url })
  }

  return (
    <Button
      className={classNames("bg-social-twitter", props.className)}
      href={"https://twitter.com/share?url=" + props.link}
      onClick={() => handleShareTwitter(props.link)}
      label="Share to Twitter"
      size={props.size}
      theme="dark"
      target="_blank"
      rel="noopener"
      iconBefore={<TwitterIcon />}
    />
  )
}

ShareLinkTwitterButton.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  size: PropTypes.string,
}

export default ShareLinkTwitterButton
