import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Constants from "lib/constants"
import { event } from "lib/gtag"
import Link from "next/link"
const TextBanner = ({
  label,
  href,
  target,
  rel,
  isCard,
  buttonLabel,
  screen,
  className,
}) => {
  const handleClickBanner = () => {
    event({ action: "TextBanner", category: "Click", label: screen })
  }

  return (
    <Link href={href} prefetch={false}>
      <a
        target={target}
        rel={rel}
        onClick={() => handleClickBanner()}
        className={classNames(
          "bg-base-accent-notify-light flex flex-col items-center w-full fs-base-paragraph-regular lg:fs-large-regular leading-paragraph lg:leading-wide text-center whitespace-normal px-4 md:px-0",
          href === "#" && "pointer-events-none cursor-default",
          isCard ? "rounded-card py-6 lg:py-8" : "py-8 lg:py-10",
          className,
        )}>
        <span dangerouslySetInnerHTML={{ __html: label }} />
        <span className="font-medium underline">{buttonLabel}</span>
      </a>
    </Link>
  )
}

TextBanner.defaultProps = {
  label:
    "NobodySurf warmly asks for your support.<br>Monthly or one-time, at any amount you like.",
  buttonLabel: "Visit Donation Page",
  target: "_self",
  rel: "noopener",
  href: "https://nobodysurf.com/donate/",
  screen: "",
}

// TextBanner.defaultProps = {
//   target: "_self",
//   rel: "noopener",
//   href: "#",
// }

TextBanner.propTypes = {
  label: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  isCard: PropTypes.bool,
  buttonLabel: PropTypes.string,
  screen: PropTypes.string,
  className: PropTypes.string,
}

export default TextBanner
