function parseYouTube(str) {
  var re = /\/\/(?:www\.)?youtu(?:\.be|be\.com)\/(?:watch\?v=|embed\/)?([a-z0-9_\\-]+)/i
  var matches = re.exec(str)
  return matches
}

function parseVimeo(str) {
  var re = /\/\/(?:www\.)?vimeo.com(?:ondemand\/)?(?:.*\/)?([0-9a-z\-_]+)/i
  var matches = re.exec(str)
  return matches
}

export function embed(mediaURL) {
  var result = parseYouTube(mediaURL)
  if (result != null) {
    return `https://www.youtube.com/embed/${result[1]}`
  }
  result = parseVimeo(mediaURL)
  if (result != null) {
    return `https://player.vimeo.com/video/${result[1]}`
  }
  return null
}
