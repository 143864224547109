import React from "react"
import PropTypes from "prop-types"
import SectionListGrid from "components/Section/List/Grid"
import CardVideo from "components/UI/Card/Video"
import formatVideoDuration from "lib/format/formatVideoDuration"
import formatVideoMetadataDescription from "lib/format/formatVideoMetadataDescription"

const VideoRelatedVideos = (props) => {
  if (props.status) return <div>{props.status}</div>

  return (
    <div className={props.className}>
      <div className="py-2 box-x-wrapper fs-small-regular xl:px-10 max:px-20">
        Related Videos
      </div>

      <SectionListGrid
        className="pt-4"
        wrapperClassName="xl:px-10 max:px-20"
        items={props.videos}
        maxItemsPerRow={4}
        skeletonRows={1}
        renderItem={(item, index) => (
          <CardVideo
            position={index}
            id={item?.movie_id}
            href={item?.web_url}
            thumbnail={item?.image_path}
            title={item?.movie_title}
            duration={formatVideoDuration(item)}
            description={item?.description}
            metadataDescription={formatVideoMetadataDescription(item)}
            key={item?.movie_id || index}
          />
        )}
      />
    </div>
  )
}

VideoRelatedVideos.propTypes = {
  className: PropTypes.string,
  status: PropTypes.number,
  videos: PropTypes.array,
}

export default VideoRelatedVideos
