import Head from "next/head"
import PropTypes from "prop-types"
import { embed } from "lib/embed"
import Publisher from "./Publisher"
const { Duration } = require("luxon")

const Video = ({
  title,
  description,
  images,
  uploadDate,
  duration,
  mediaUrl,
}) => {
  const isoDuration = Duration.fromMillis(duration * 1000).toISO()
  const embedUrl = embed(mediaUrl)
  const article = {
    ["@context"]: "https://schema.org",
    ["@type"]: "VideoObject",
    name: title,
    description: description,
    thumbnailUrl: images,
    uploadDate: uploadDate,
    duration: isoDuration,
    embedUrl: embedUrl,
    publisher: Publisher(),
  }

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(article),
        }}
      />
    </Head>
  )
}

Video.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string),
  uploadDate: PropTypes.string,
  duration: PropTypes.number,
  mediaUrl: PropTypes.string,
}

export default Video
