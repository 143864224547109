import React from "react"
import PropTypes from "prop-types"
import VideoPlayer from "components/Video/Player"
import VideoHeader from "components/Video/Header"
import VideoDescription from "components/Video/Description"
import VideoRelatedLinks from "components/Video/RelatedLinks"
import VideoRelatedVideos from "components/Video/RelatedVideos"
import RichResultsVideo from "components/Meta/RichResults/Video"
import Page from "components/Page"
import Error from "../_error"
import { OGP } from "components/Meta"
import { Endpoint, ApiPath } from "lib/endpoint"
import Head from "next/head"
import { isEmpty } from "lib/utils"
import TextBanner from "components/UI/TextBanner"
import SubNavigation from "components/SubNavigation/SubNavigation"

const Index = (props) => {
  if (isEmpty(props?.video)) {
    return <Error statusCode={404} />
  }

  return (
    <>
      <OGP meta={props.meta} />
      <Page indicatorLabel="Watch on NobodySurf App" {...props}>
        <Head>
          <link rel="amphtml" href={toAmpUrl(props.video.web_url)} />
        </Head>
        <RichResultsVideo
          title={props.video.movie_title}
          description={props.video.description}
          images={[props.video.image_path]}
          uploadDate={props.video.published_at}
          duration={props.video.duration}
          mediaUrl={props.video.media_url}
        />
        <SubNavigation />
        <input type="hidden" name="mediaurl" value={props.video.media_url} />
        <VideoPlayer
          title={props.video.movie_title}
          poster={props.video.image_path}
          url={props.video.media_url}
        />

        <div className="flex flex-col pb-wrapper space-y-wrapper">
          <div className="flex flex-col box-x-wrapper lg:max-w-lg">
            <VideoHeader
              title={props.video.movie_title}
              places={props.video.places}
              bottomDivider
            />
            <VideoDescription tags={props.video.tags} />
            <VideoRelatedLinks links={props.video.links} />
            <TextBanner isCard className="mt-6" screen="movie" />
          </div>

          <VideoRelatedVideos videos={props.video.related} />
        </div>
      </Page>
    </>
  )
}

function toAmpUrl(url) {
  var tailSlash = url.replace(/\/$/, "") + "/"
  return tailSlash + "amp/"
}

export const getServerSideProps = async ({ res, query }) => {
  try {
    const response = await fetch(`${Endpoint}${ApiPath}/contents/${query.slug}`)
    const video = await response.json()

    if (response.status != 200) {
      return {
        props: {
          video: {},
          meta: {},
        },
      }
    }
    const meta = {
      title: video.movie_title + " | NobodySurf",
      description: video.description,
      image: video.image_path,
      url: video.web_url,
    }
    res.statusCode = response.status
    return {
      props: {
        video: video,
        meta: meta,
      },
    }
  } catch (e) {
    return {
      props: {
        video: {},
        meta: {},
      },
    }
  }
}

Index.propTypes = {
  video: PropTypes.shape({
    movie_id: PropTypes.number,
    movie_title: PropTypes.string,
    image_path: PropTypes.string,
    media_url: PropTypes.string,
    web_url: PropTypes.string,
    duration: PropTypes.number,
    description: PropTypes.string,
    published_at: PropTypes.string,
    places: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          tag_name: PropTypes.string,
          tag_url: PropTypes.string,
          unicode: PropTypes.string,
        }),
      ),
    ),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        link_text: PropTypes.string,
        link_type: PropTypes.number,
        link_type_name: PropTypes.string,
        link_url: PropTypes.string,
      }),
    ),
    tags: PropTypes.object,
    related: PropTypes.array,
  }),
}

export default Index
